import React, { useState, useEffect, useRef } from 'react';
import { useLoadScript, Autocomplete } from '@react-google-maps/api';

const libraries = ['places'];

const PlacesAutocomplete = ({ value, onChange, onSelect, id, name, className, required }) => {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyDeuQ5Nguld0t6gV6dGM8u-6MPF0pJokco",
        libraries,
    });

    const autocompleteRef = useRef(null); // Reference to the Autocomplete instance
    const [inputValue, setInputValue] = useState(value);

    // Effect to sync external value changes (e.g., initial value or reset)
    useEffect(() => {
        setInputValue(value);
    }, [value]);

    const handlePlaceSelect = () => {
        const place = autocompleteRef.current.getPlace();
        const address = place.formatted_address || place.name; // Fallback to place.name if formatted_address isn't available
        setInputValue(address); // Update local state
        // Call onChange prop to update external state, mimicking a standard input change event
        // onChange({ target: { name, value: address } });
        if (onSelect) {
            onSelect(place);
          };
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
        if (onChange) {
            onChange(e); // Call onChange with the event object
          }
    };

    const handleInputPaste = (event) => {
        event.preventDefault();
        alert('Please select an address from the suggestions.');
      };

    if (!isLoaded) return <div>Loading...</div>;

    return (
        <Autocomplete
            onLoad={(auto) => (autocompleteRef.current = auto)}
            onPlaceChanged={handlePlaceSelect}
            className={className}
        >
            <input
                type="text"
                placeholder="Enter a location"
                id={id}
                name={name}
                value={inputValue}
                className="w-full"
                onChange={handleInputChange} // Handle manual changes to the input
                onPaste={handleInputPaste} // Prevent pasting into the input field
                required={required}
            />
        </Autocomplete>
    );
};

export default PlacesAutocomplete;
