import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from './partners_logo.png';
import 'react-datepicker/dist/react-datepicker.css';
import PlacesAutocomplete from './Autocomplete';
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./ui/tabs";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "./ui/popover";
import { Calendar } from "./ui/calendar";
import { Button } from "./ui/button";
import { format } from "date-fns"
import { Calendar as CalendarIcon } from "lucide-react"
import { Checkbox } from "./ui/checkbox"
import {
  Sheet,
  SheetContent,
  SheetTrigger,
} from "./ui/sheet"
 
import { cn } from "./ui/@/lib/utils";
import LogRocket from 'logrocket';
import InputMask from 'react-input-mask';
import MaskedInput from 'react-text-mask';


const emailMask = [
  /[a-z0-9._%+-]/, /[a-z0-9._%+-]/, /[a-z0-9._%+-]/, /[a-z0-9._%+-]/, /[a-z0-9._%+-]/, /[a-z0-9._%+-]/, /[a-z0-9._%+-]/, /[a-z0-9._%+-]/, /[a-z0-9._%+-]/, /[a-z0-9._%+-]/,
  '@',
  /[a-z0-9.-]/, /[a-z0-9.-]/, /[a-z0-9.-]/, /[a-z0-9.-]/, /[a-z0-9.-]/, /[a-z0-9.-]/, /[a-z0-9.-]/, /[a-z0-9.-]/, /[a-z0-9.-]/,
  '.',
  /[a-z]/, /[a-z]/, /[a-z]/, /[a-z]/, /[a-z]/,
];

function EmailInput({ value, onChange }) {
  return (
    <MaskedInput
      mask={emailMask}
      placeholder="person@domain.com"
      className="w-2/3 p-2 border rounded"
      value={value}
      onChange={onChange}
      guide={false}
    />
  );
}
LogRocket.init('ixofem/start-hiring-form');

const ClientForm = () => {

  /* OnSubmit for Alert variable */
  const navigate = useNavigate()

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!isAddressSelected) {
      alert("Please select a valid address from the suggestions.");
      return;
    }
    fetch(`https://hooks.zapier.com/hooks/catch/16197236/39mr0dh/`, {
    method: "POST",
    body: JSON.stringify(formData),
    })

    navigate('/Success', {
      state: {
        formData: formData,
      },
    });
  };

    /* Datepicker variables */

    const handleDateChange = (date) => {
        setDate(date);
        setFormData((prevFormData) => ({
          ...prevFormData,
          "startDate": date,
        }));
    };

    /* Dropdown variables */
    const handleDropdownChange = (event) => {
      handleChange(event);
    };

    // Parse Query Paramters from the URL for autocomplete
    useEffect(() => {
      const queryParams = new URLSearchParams(window.location.search);
      const position = queryParams.get('position'); 
      const company = queryParams.get('company');
      const firstName = queryParams.get('firstName'); 

      if (position || company || firstName) {
        setFormData(prevFormData => ({
          ...prevFormData,
          firstName: firstName || prevFormData.firstName, // Use the existing value if the parameter is not found
          company: company || prevFormData.company, // Use the existing value if the parameter is not found
          hiringPosition: position || prevFormData.hiringPosition,
        }));
      }
    }, []);

    /* Form data variables */
    const [formData, setFormData] = useState({
      company: '',
      address: '',
      state: '',
      branchId: '',
      regionCode:'',
      branchEmail:'',
      firstName: '',
      lastName: '',
      companyEmail: '',
      phone:'',
      howHeardOf:'',
      hiringPosition:'',
      startDate:'',
      length:'Indefinitely',
      dayShift:false,
      afternoonShift:false,
      nightShift:false,
      other:false,
      numberOfAssociates:'1-5',
      rate:'',
      managerName:'',
      managerPhone:'',
      managerEmail:'',
    });

    const [tempAddress, setTempAddress] = useState('');
    const [isAddressSelected, setIsAddressSelected] = useState(false);
    
    const handleAddressChange = (event) => {
      setTempAddress(event.target.value);
      setIsAddressSelected(false);
    };

    const handleAddressSelect = (place) => {
      const address = place.formatted_address || place.name;
      setFormData((prevFormData) => ({
        ...prevFormData,
        address: address,
      }));
      setTempAddress(address);
      setIsAddressSelected(true);
    };

    const handleChange = (event) => {
      const { name, value } = event.target;
      // Optional email validation check

      if (name !== 'address') {
        // Update formData for all fields except 'address'
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      } else {
        // Handle changes to the 'address' field without updating formData.address
        setFormData((prevFormData) => ({
          ...prevFormData,
          state: '',
          branchId: '',
        }));
        setIsAddressSelected(false);
        setOptions([]); // Clear branch options
      }
    };
    

    const [allowPreferencesTab, setAllowPreferencesTab] = useState(false);

    useEffect(() => {
      function checkRequiredFields(){
        if(
          formData.company !== '' && 
          isAddressSelected &&
          formData.state !== '' && 
          formData.branchId !== '' && 
          formData.firstName !== '' && 
          formData.phone !== '') {
            setAllowPreferencesTab(true);
        } else {
            setAllowPreferencesTab(false);
        }
      }
      
      checkRequiredFields();
    }, [formData, isAddressSelected]);


    const handleDayCheckboxChange = (event) => {
      
      setFormData((prevFormData) => ({
        ...prevFormData,
        dayShift:!formData["dayShift"],
      }));
    };

    const handleAfternoonCheckboxChange = (event) => {
      
      setFormData((prevFormData) => ({
        ...prevFormData,
        afternoonShift:!formData["afternoonShift"],
      }));
    };

    const handleNightCheckboxChange = (event) => {
      
      setFormData((prevFormData) => ({
        ...prevFormData,
        nightShift:!formData["nightShift"],
      }));
    };

    const handleOtherCheckboxChange = (event) => {
      
      setFormData((prevFormData) => ({
        ...prevFormData,
        other:!formData["other"],
      }));
    };

    /* Variables for populating branch dropdown */
    const [options, setOptions] = useState([]);


    useEffect(() => {
      const fetchBranches = async () => {

        const address = formData.address;

        function extractPostalCode(address) {
          const postalCodeRegex = /\b\d{5}\b/;
          const match = address.match(postalCodeRegex);
          return match ? match[0] : null;
        };
        
        const postalCode = extractPostalCode(address);
        
        async function geocodeAddress(address) {
          try {
            const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyDeuQ5Nguld0t6gV6dGM8u-6MPF0pJokco`, {
              method: "GET",
            });
      
            const geocodingJson = await response.json();
            if (geocodingJson.results && geocodingJson.results.length > 0) {
              const lat = geocodingJson.results[0].geometry.location.lat;
              const lng = geocodingJson.results[0].geometry.location.lng;
              return {'lat': lat, 'lng': lng };
            } else {
              throw new Error('No geocoding results found');
            }
              
          } catch (error) {
            console.error('Error fetching data:', error);
            throw new Error("Error fetching data");
          }
        }
        
        async function fetchActiveBranches(lat, lng, postalCode) {
          var url;
          if (lat && lng) {
            url = `https://tw-api-proxy-d98b3902e8eb.herokuapp.com/api/proxy/branches/nearby?lat=${lat}&lng=${lng}`;
          } else if (postalCode) {
            url = `https://tw-api-proxy-d98b3902e8eb.herokuapp.com/api/proxy/branches/nearby?postalCode=${postalCode}`;
          } else {
            url = `https://tw-api-proxy-d98b3902e8eb.herokuapp.com/api/proxy/branches/nearby`;
          }
      
          try {
            const response = await fetch(url, {
              method: "POST",
              headers: {
                "Permission": "26cb60ca-d995-4240-ad82-95be15fa2014"
              }
            }
            );
      
            const responseData = await response.json();
            const rawData = responseData.data;
            const branches = rawData.filter((item) => (item.address.region === formData.state) && item.isActive);
            return branches;
          } catch (error) {
            console.error('Error fetching data:', error);
            throw new Error("Error fetching data");
          }
        }

        // Atempt to get the lat/lng for greater accuracy in branch selection
        const location = await geocodeAddress(address);
        const branches = await fetchActiveBranches(location.lat, location.lng, postalCode);

        if (Array.isArray(branches)) {
          
          // Transform the 'branches' into options for the dropdown
          const transformedOptions = branches.map((item) => {
            
            const distance = item.distanceToLocation ? `(${item.distanceToLocation.toFixed(2)} miles)` : '';

            return {
              value: item.branchId,
              label: `${item.branchName.replace(/\d+/g, '')}` + distance,
              key: item.branchId,
            };
          });

          setOptions(transformedOptions); // Set the transformed data as options
          setFormData((prevFormData) => ({
            ...prevFormData,
            branchId: transformedOptions[0].value,
          }));
        } else {
          console.error('Data is not an array:', branches);
        }
      };

      if (isAddressSelected && formData.state !== '') {
        fetchBranches();
      }
    }, [isAddressSelected, formData.state]);

    /* Variables for getting branch email */
    const [branchId, setBranchId] = useState();

    useEffect(() => {
      const getBranchEmail = async () => {

        const branchId = formData.branchId;

        try {
          const params = new URLSearchParams({ branchId }); // Construct URL parameters
          const response = await fetch(`https://tw-api-proxy-d98b3902e8eb.herokuapp.com/api/proxy/branch?${params.toString()}`, {
            method: "POST",
            headers: {
              "Permission": "26cb60ca-d995-4240-ad82-95be15fa2014"
            }
          });
          const responseData = await response.json();
          const email = responseData.emailAddress;
          const regionCode = responseData.regionCode;
          formData.branchEmail = email;
          formData.regionCode = regionCode;
          console.log(formData);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      if (formData.branchId !== '') {
        // Fetch data from the API when the dependent field changes
        getBranchEmail();
      }
    }, [branchId, formData, formData.branchId]);

    const handleStateChange = (event) => {
      const { name, value } = event.target;
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
        branchId: '',
      }));
    };

    const handleBranchChange = (event) => {
      const { name, value, } = event.target;
      const branchKey = event.target.value;

      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));

      setBranchId(branchKey);
    };


    const handleStateClick = (event) => {
      if (!isAddressSelected) {
        alert("Please type your address first");
        event.preventDefault();
        event.stopPropagation();
      }
    };
    
    const handleBranchClick = (event) => {
      if (options.length === 0) {
        alert("Please select a State first");
        event.preventDefault();
        event.stopPropagation();
      }
    };

    const [date, setDate] = useState(0);

  
  return (
  <div className="bg-gray-100 App">

  <nav className="flex justify-between items-center bg-white p-4 border-b-2">
    <div className='w-fit'>
      <a href="https://www.partnerspersonnel.com/">
        <img src={logo} alt="Company Logo" className="h-12 hidden md:block" />
      </a>
      <a href="https://www.partnerspersonnel.com/">
        <img src="partnerP.png" alt="Company Logo" className="h-12 block md:hidden" />
      </a>
    </div>
    <div className="ml-2 flex items-center">
      <a href="https://jobs.partnerspersonnel.com/job-search?limit=25" className="no-underline mr-4 md:border rounded-2 p-1 px-3">
        <div className="text-black hidden md:block">Looking for a job? Click here</div>
        {/* <div className="text-black hidden md:block">¿En busca de un trabajo? Haga clic aquí</div> */}
      </a>

        <div className="grid grid-cols-2 gap-2 ml-4">
          <Sheet key="right">
          <SheetTrigger><img src="Menu.png" alt="menu" /></SheetTrigger>
            <SheetContent side="right">

              <Tabs defaultValue="english" className="w-full mt-8">
                <TabsList>
                  <TabsTrigger value="english" className="focus:bg-slate-500 focus:text-white">English</TabsTrigger>
                  <TabsTrigger value="spanish" className="focus:bg-slate-500 focus:text-white">Español</TabsTrigger>
                </TabsList>
                <hr className="border-t border-gray-500" />
                <TabsContent value="english">  
                  <div className="flex flex-col">
                    <a href="https://www.partnerspersonnel.com/" className="no-underline my-4">
                      <div className="text-[#1C3F6C] text-3xl font-semibold">Home</div>
                    </a>
                  </div>
                  <div className="flex flex-col">
                    <a href="https://www.partnerspersonnel.com/find-work/" className="no-underline my-4">
                      <div className="text-[#1C3F6C] text-3xl font-semibold">Find Work</div>
                    </a>
                  </div>
                  <div className="flex flex-col">
                    <a href="https://www.partnerspersonnel.com/find-people/" className="no-underline my-4">
                      <div className="text-[#1C3F6C] text-3xl font-semibold">Staffing Solutions</div>
                    </a>
                  </div>
                  <div className="flex flex-col">
                    <a href="https://www.partnerspersonnel.com/announcements2/" className="no-underline my-4">
                      <div className="text-[#1C3F6C] text-3xl font-semibold">Resources</div>
                    </a>
                  </div>
                  <div className="flex flex-col">
                    <a href="https://www.partnerspersonnel.com/about-us/" className="no-underline my-4">
                      <div className="text-[#1C3F6C] text-3xl font-semibold">About Us</div>
                    </a>
                  </div>
                  <div className="flex flex-col">
                    <a href="https://www.partnerspersonnel.com/locations-list/" className="no-underline my-4">
                      <div className="text-[#1C3F6C] text-3xl font-semibold">Locations</div>
                    </a>
                  </div>
                </TabsContent>
                <TabsContent value="spanish">  
                  <div className="flex flex-col">
                    <a href="https://www.partnerspersonnel.com/" className="no-underline my-4">
                      <div className="text-[#1C3F6C] text-3xl font-semibold">Inicio</div>
                    </a>
                  </div>
                  <div className="flex flex-col">
                    <a href="https://www.partnerspersonnel.com/find-work/" className="no-underline my-4">
                      <div className="text-[#1C3F6C] text-3xl font-semibold">Encontrar Trabajo</div>
                    </a>
                  </div>
                  <div className="flex flex-col">
                    <a href="https://www.partnerspersonnel.com/find-people/" className="no-underline my-4">
                      <div className="text-[#1C3F6C] text-3xl font-semibold">Soluciones</div>
                    </a>
                  </div>
                  <div className="flex flex-col">
                    <a href="https://www.partnerspersonnel.com/announcements2/" className="no-underline my-4">
                      <div className="text-[#1C3F6C] text-3xl font-semibold">Recursos</div>
                    </a>
                  </div>
                  <div className="flex flex-col">
                    <a href="https://www.partnerspersonnel.com/about-us/" className="no-underline my-4">
                      <div className="text-[#1C3F6C] text-3xl font-semibold">Sobre Nosotros</div>
                    </a>
                  </div>
                  <div className="flex flex-col">
                    <a href="https://www.partnerspersonnel.com/locations-list/" className="no-underline my-4">
                      <div className="text-[#1C3F6C] text-3xl font-semibold">Ubicaciones</div>
                    </a>
                  </div>
                </TabsContent>
              </Tabs>

              
            </SheetContent>
          </Sheet>
      </div>
    </div>
  </nav>
  <div className="flex flex-col justify-between  md:flex-row md:!px-24 md:!py-12">


  <div className="flex w-2/5 justify-center">
    <div className="hidden md:flex md:flex-col ml-4 md:ml-0 w-full md:w-[400px]">

      <div className="font-semibold text-slate-900 text-4xl leading-16 mt-16 mb-8 mr-12">
        Finding employees to fill your shifts has never been easier.
      </div>
      <div className="text-slate-900 text-xl leading-16 mb-16 mr-12">
        Fill out the form to get started with hassle-free staffing services today!
      </div>
      <div className="flex items-center">
        <img src="check.svg" alt="checkmark" className="p-2 mr-4 border border-gray-200 rounded-full bg-white" />
        <div className="text-md">Local market expertise and quick response time</div>
      </div>
      <div className="flex items-center mt-4">
        <img src="check.svg" alt="checkmark" className="p-2 mr-4 border border-gray-200 rounded-full bg-white" />
        <div className="text-md">Skilled associates and competitive rates</div>
      </div>
      <div className="flex items-center mt-4">
        <img src="check.svg" alt="checkmark" className="p-2 mr-4 border border-gray-200 rounded-full bg-white" />
        <div className="text-md">Personalized and streamlined staffing solutions</div>
    </div>

    <hr className="border-t border-gray-500 mr-4" />

    <div className="flex items-center justify-between mt-4 mr-16">
      <img src="bestGreen.png" alt="bestTalent" />
      <img src="bestBlue.png" alt="bestTalent" />
      <img src="sia.png" alt="bestTalent" />
    </div>
    </div>
  </div>


    <form className="flex flex-col p-6 w-full md:w-3/5 bg-white border rounded drop-shadow-xl" onSubmit={handleSubmit}>

    <Tabs defaultValue="contact" className="w-full">
      <div className="flex text-slate-900 font-semibold text-lg block md:hidden text-center p-1 px-3 mb-4">Request Talent</div>
      <TabsList>
        <TabsTrigger value="contact" className="focus:bg-slate-500 focus:text-white">Contact</TabsTrigger>
        <TabsTrigger value="preferences" className="focus:bg-slate-500 focus:text-white" disabled={!allowPreferencesTab}>Preferences</TabsTrigger>
      </TabsList>
      <hr className="border-t border-gray-500" />
      <TabsContent value="contact">  

        <div className="flex justify-between items-center mb-4">
          <label htmlFor="company" className="block mb-2 w-1/5">Company:<span className="text-red-500">*</span></label>
          <input type="text" id="company" name="company" placeholder="Company name" className="w-2/3 p-2 border rounded" value={formData.company} onChange={handleChange} required />
        </div>

        <div className="flex justify-between items-center mb-4">
          <label htmlFor="address" className="block mb-2 w-1/5">Address:<span className="text-red-500">*</span></label>
          <PlacesAutocomplete 
          id="address" 
          name="address" 
          className="w-2/3 p-2 border rounded" 
          value={tempAddress} 
          onChange={handleAddressChange} 
          onSelect={handleAddressSelect}
          required 
          />  
        </div>

        <div 
          className="flex justify-between items-center mb-4" 
          onClick={handleStateClick}
        >
          <label htmlFor="state" className="block mb-2 w-1/5">State:<span className="text-red-500">*</span></label>
          <select type="text" 
            className="w-2/3 p-2 border rounded" 
            placeholder="Select a state" 
            id="state" name="state" 
            value={formData.state} 
            onChange={handleStateChange} 
            disabled={!isAddressSelected} 
            required
            style={{ pointerEvents: !isAddressSelected ? 'none' : 'auto' }}
          >
            <option value="" disabled hidden>Select your state</option>
            <option value="AL">AL</option>
            <option value="AZ">AZ</option>
            <option value="CA">CA</option>
            <option value="CO">CO</option>
            <option value="FL">FL</option>
            <option value="GA">GA</option>
            <option value="IA">IA</option>
            <option value="IL">IL</option>
            <option value="IN">IN</option>
            <option value="KY">KY</option>
            <option value="MD">MD</option>
            <option value="MI">MI</option>
            <option value="MS">MS</option>
            <option value="NC">NC</option>
            <option value="NE">NE</option>
            <option value="NJ">NJ</option>
            <option value="NV">NV</option>
            <option value="NY">NY</option>
            <option value="OH">OH</option>
            <option value="PA">PA</option>
            <option value="SC">SC</option>
            <option value="TN">TN</option>
            <option value="TX">TX</option>
            <option value="UT">UT</option>
            <option value="VA">VA</option>
            <option value="WA">WA</option>
            <option value="WI">WI</option>
          </select>
        </div>

        <div className="flex justify-between items-center mb-4" onClick={handleBranchClick}>
          <label htmlFor="branch" className="block mb-2 w-1/5">Branch:<span className="text-red-500">*</span></label>
          
          <select 
            type="text" 
            className={`w-2/3 p-2 border rounded ${
              options.length === 0 ? 'bg-gray-200 text-gray-500 cursor-not-allowed' : ''
            }`}
            placeholder="Select a branch" 
            id="branch" 
            name="branchId" 
            value={formData.branchId} 
            onChange={handleBranchChange} 
            disabled={options.length === 0} 
            required
            style={{ pointerEvents: options.length === 0 ? 'none' : 'auto' }}
          >
            <option value='' hidden disabled>Select the branch closest to you</option>
            {options.map((option) => (
              <option key={option.key} value={option.key}>
                {option.label}
               </option>
              ))}
          </select>
        </div>

        <div className="flex justify-between items-center mb-4">
          <label htmlFor="firstName" className="block mb-2 w-1/5">First Name:<span className="text-red-500">*</span></label>
          <input type="text" id="firstName" name="firstName" placeholder="First Name" className="w-2/3 p-2 border rounded" value={formData.firstName} onChange={handleChange} required/>
        </div>
        <div className="flex justify-between items-center mb-4">
          <label htmlFor="lastName" className="block mb-2 w-1/5">Last Name:</label>
          <input type="text" id="lastName" name="lastName" placeholder="Last Name" className="w-2/3 p-2 border rounded" value={formData.lastName} onChange={handleChange} />
        </div>
        <div className="flex justify-between items-center mb-4">
          <label htmlFor="companyEmail" className="block mb-2 w-1/5">Company Email:</label>
          <input type="text" id="companyEmail" name="companyEmail" placeholder="Company Email" className="w-2/3 p-2 border rounded" value={formData.companyEmail} onChange={handleChange} />
        </div>
        <div className="flex justify-between items-center mb-4">
          <label htmlFor="phone" className="block mb-2 w-1/5">Company Phone:<span className="text-red-500">*</span></label>
          <InputMask
  mask="999-999-9999"
  value={formData.phone}
  onChange={handleChange}
  maskChar=""
>
  {() => (
    <input
      type="text"
      alwaysShowMask="true"
      id="phone"
      name="phone"
      placeholder="555-555-5555"
      className="w-2/3 p-2 border rounded"
      required
    />
  )}
</InputMask>        </div>
        <div className="flex justify-between items-center mb-4">
          <label htmlFor="howHeardOf" className="block mb-2 w-1/5">How did you find us?:<span className="text-red-500">*</span></label>
          <select type="text" className="w-2/3 p-2 border rounded" placeholder="Select an option" id="howHeardOf" name="howHeardOf" value={formData.howHeardOf} onChange={handleDropdownChange} required>
            <option value="" disabled hidden>Select an option</option>
            <option value="Google">Google</option>
            <option value="Facebook">Facebook</option>
            <option value="LinkedIn">LinkedIn</option>
            <option value="Indeed">Indeed</option>
            <option value="Friend/Family">Friend/Family</option>
            <option value="Partners Personnel Website">Partners Personnel Website</option>
            <option value="Another Company that uses Partners">Another Company that uses Partners</option>
            <option value="Business Associate or Colleague">Business Associate or Colleague</option>
            <option value="Other">Other</option>
          </select>
        </div>

        <hr className="border-t border-gray-500 mb-4" />

        <TabsList className="flex justify-end bg-white">
        <TabsTrigger value="preferences" disabled={!allowPreferencesTab} className="disabled:bg-slate-300 bg-slate-800 text-white">Continue</TabsTrigger>
          {/* <TabsTrigger value={continueValue} onClick={() => 
            {setAttemptedNavigate(true); 
              if(allRequiredFieldsFilled()){
                setContinueValue("preferences");
                setAllowPreferencesTab(true);
              }
              else{

              } }} 
            className="py-2 px-4 bg-slate-800 text-white rounded mt-4 md:w-1/3 self-end">Continue</TabsTrigger> */}
        </TabsList>
      </TabsContent>
      <TabsContent value="preferences">
        <div className="flex justify-between items-center mb-4">
          <label htmlFor="hiringPosition" className="block mb-2 w-1/5">Hiring Position:</label>
          <input type="hiringPosition" id="hiringPosition" name="hiringPosition" placeholder="Position to fill" className="w-2/3 p-2 border rounded" value={formData.hiringPosition} onChange={handleChange} />
        </div>

        <div className="flex justify-between items-center mb-4">
          <label htmlFor="date" className="block mb-2 w-1/5">Start Date:</label>
          <Popover>
            <PopoverTrigger asChild>
              <Button
                variant={"outline"}
                className={cn(
                  "w-2/3 justify-start text-left font-normal",
                  !date && "text-muted-foreground"
                )}
              >
                <CalendarIcon className="mr-2 h-4 w-4" />
                {date ? format(date, "PPP") : <span>Pick a date</span>}
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0">
              <Calendar
                mode="single"
                selected={date}
                onSelect={handleDateChange}
                initialFocus
              />
            </PopoverContent>
        </Popover>
        </div>

        <div className="flex justify-between items-center mb-4">
          <label htmlFor="length" className="block mb-2 w-1/5">Length of Assignment:</label>
          
          <select type="text" className="w-2/3 p-2 border rounded" placeholder="Indefinitely" id="length" name="length" value={formData.length} onChange={handleDropdownChange}>
            
          <option value="">Indefinitely</option>
          <option value="1 week">1 Week</option>
          <option value="1 month">1 month</option>
          <option value="6 months">6 months</option>
          <option value="1 year">1 year</option>

          </select>
        </div>

        <div className="flex justify-between items-center mb-4">
          <label htmlFor="shiftToFill" className="block mb-2 w-1/5">Shift to Fill:</label>
          <div className="flex flex-col w-2/3 p-2 border rounded">
            <div className="flex">
              <div className="flex w-1/2 mr-2 items-center border rounded-4 px-4 py-2 text-xs">
                <Checkbox className=" mr-2 border-gray-200" onCheckedChange={handleDayCheckboxChange} />
                Day Shift (1st)
              </div>
              <div className="flex w-1/2 items-center border rounded-4 px-4 text-xs">
                <Checkbox className="mr-2 border-gray-200" onCheckedChange={handleAfternoonCheckboxChange} />
                Afternoon Shift (2nd)
              </div>
            </div>
            <div className="flex mt-2">
              <div className="flex w-1/2 mr-2 items-center border rounded-4 px-4 py-2 text-xs">
                <Checkbox className="mr-2 border-gray-200" onCheckedChange={handleNightCheckboxChange} />
                Night Shift (3rd)
              </div>
              <div className="flex w-1/2 items-center border rounded-4 px-4 text-xs">
                <Checkbox className="mr-2 border-gray-200" onCheckedChange={handleOtherCheckboxChange} />
                Other
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-between items-center mb-4">
          <label htmlFor="numberOfAssociates" className="block mb-2 w-1/5">Number of Associates:</label>
          
          <select type="text" className="w-2/3 p-2 border rounded" placeholder="1-5" id="numberOfAssociates" name="numberOfAssociates" value={formData.numberOfAssociates} onChange={handleDropdownChange}>
            
          <option value="">1-5</option>
          <option value="6-10">6-10</option>
          <option value="11-15">11-15</option>
          <option value="16-20">16-20</option>
          <option value="20+">20+</option>

          </select>
        </div>

        <div className="flex justify-between items-center mb-4">
          <label htmlFor="rate" className="block mb-2 w-1/5">Hourly Rate:</label>
          <input type="rate" id="rate" name="rate" placeholder="$0" className="w-2/3 p-2 border rounded" value={formData.rate} onChange={handleChange} />
        </div>
        <div className="flex justify-between items-center mb-4">
          <label htmlFor="managerName" className="block mb-2 w-1/5">Shift Manager:</label>
          <input type="managerName" id="managerName" name="managerName" placeholder="Name of manager" className="w-2/3 p-2 border rounded" value={formData.managerName} onChange={handleChange} />
        </div>
        <div className="flex justify-between items-center mb-4">
          <label htmlFor="managerPhone" className="block mb-2 w-1/5">Manager's Phone:</label>
          <input type="managerPhone" id="managerPhone" name="managerPhone" placeholder="Phone of manager" className="w-2/3 p-2 border rounded" value={formData.managerPhone} onChange={handleChange} />
        </div>
        <div className="flex justify-between items-center mb-4">
          <label htmlFor="managerEmail" className="block mb-2 w-1/5">Manager's Email:</label>
          <input type="managerEmail" id="managerEmail" name="managerEmail" placeholder="Email of manager" className="w-2/3 p-2 border rounded" value={formData.managerEmail} onChange={handleChange} />
        </div>

        <hr className="border-t border-gray-500" />

        <div className="flex justify-end">

        <button type="submit" className="py-2 px-4 bg-slate-800 text-white rounded md:w-1/3 self-end">Submit</button> 

        </div>
      </TabsContent>
    </Tabs>
    
  </form>
  </div>


</div>
  );
};

export default ClientForm;